.page {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 30rem;

    .homeImage {
      flex-grow: 0;
    }

    & > * {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
