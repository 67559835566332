.header {
  display: flex;
  align-items: center;
  background-color: rgb(28, 30, 31);
  color: white;
  position: relative;
  height: 3rem;
  & > * {
    display: inline-flex;
    align-items: center;
    height: 100%;
    padding: 0 0.5rem;
  }
  .homeLink {
    display: flex;
    align-items: center;
    .logo {
      font-size: 2rem;
      i::before {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  .separator {
    flex-grow: 1;
  }

  a,
  button {
    color: white;
    text-decoration: none;
    &:hover {
      background-color: salmon;
    }
  }
}
